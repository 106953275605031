
















































































































import axios from "../../axios";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, sameAs } from "@vuelidate/validators";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "AppRegisterForm",

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    name: "",
    password: "",
    password_confirmation: "",
    hCaptcha: {
      verified: false,
      token: ""
    },
    loading: false,
    successSnackbar: false,
    errorSnackbar: false,
    errorMessage: "",
  }),

  computed: {
    nameErrors() {
      const name: any = this.v$.name;

      if (!name.$dirty)
        return [];

      const errors: any[] = [];

      if (name.required.$invalid)
        errors.push("用户名不能为空");
      if (name.minLength.$invalid)
        errors.push("用户名长度不能小于4个字符");
      if (name.maxLength.$invalid)
        errors.push("用户名长度不能大于32个字符");

      return errors;
    },
    passwordErrors() {
      const password: any = this.v$.password;

      if (!password.$dirty)
        return [];

      const errors: any[] = [];

      if (password.required.$invalid)
        errors.push("密码不能为空");
      if (password.minLength.$invalid)
        errors.push("密码长度不能小于6个字符");
      if (password.maxLength.$invalid)
        errors.push("密码长度不能大于32个字符");

      return errors;
    },
    passwordConfirmationErrors() {
      const password_confirmation: any = this.v$.password_confirmation;

      if (!password_confirmation.$dirty)
        return [];

      const errors: any[] = [];

      if (password_confirmation.required.$invalid)
        errors.push("密码确认不能为空");
      if (password_confirmation.sameAs.$invalid)
        errors.push("密码确认与密码不一致");

      return errors;
    },
  },

  components: {
    VueHcaptcha,
  },

  methods: {
    captchaVerified(token: string) {
      this.hCaptcha.verified = true;
      this.hCaptcha.token = token;
    },
    register() {
      this.loading = true;

      this.v$.$touch();
      if (this.v$.$invalid || !this.hCaptcha.verified)
        return;

      axios.post("/auth/register", {
        name: this.name,
        password: this.password,
        password_confirmation: this.password_confirmation,
        "h-captcha-response": this.hCaptcha.token,
      }).then(() => {
        this.successSnackbar = true;
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        this.errorMessage = error.message;
        
        console.error(error);

        if (error.response) {
          (this.$refs.hCaptcha as any).reset();

          error.response.data.errors.forEach((error: any) => {
            this.errorMessage += `<br>${error.detail}`;
          });
        }
        
        this.errorSnackbar = true;
      });
    }
  },

  validations() {
    return {
      name: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(32),
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(32),
      },
      password_confirmation: {
        required, 
        sameAs: sameAs(this.password)
      }
    };
  },
});


























import Vue from "vue";
import AppNav from "./components/AppNav.vue";

export default Vue.extend({
  name: "App",

  components: {
    AppNav,
  },

  data: () => ({
    drawer: null,
  })
});



















































import Vue from "vue";
import AppRegisterForm from "./forms/AppRegisterForm.vue";
import AppLoginForm from "./forms/AppLoginForm.vue";

export default Vue.extend({
  name: "AppAuthBar",

  components: {
    AppRegisterForm,
    AppLoginForm,
  },

  data: () => ({
    showRegisterForm: false,
    showLoginForm: false,
  }),
});





























import Vue from "vue";
import AppAuthBar from "./AppAuthBar.vue";

export default Vue.extend({
  name: "AppNav",

  components: {
    AppAuthBar,
  },

  data: () => ({
    items: [
      {
        icon: "mdi-home",
        text: "主页",
        to: "/",
      },
      {
        icon: "mdi-information",
        text: "关于",
        to: "/about",
      }
    ],
  })
});

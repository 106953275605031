



























import Vue from "vue";

export default Vue.extend({
  name: "AppLoginForm",

  methods: {
    login() {
      // @TODO
    }
  }
});
